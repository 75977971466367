import "./App.css";

import "react-multi-carousel/lib/styles.css";
import { Blindspot, SyntheticImages, DownstreamModel, FileUpload } from "./Components";
import { blindspotData, syntheticData, testImages } from "./data";


import React, { useState, useEffect } from 'react';

export default function App() {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [showBlindspots, setShowBlindspots] = useState(false);
  const [showSyntheticImages, setShowSyntheticImages] = useState(false);
  const [testFileUploaded, setTestFileUploaded] = useState(false);
  const [testFileUploadedOnChange, setTestFileUploadedOnChange] = useState(false);
  const [showDownstreamModel, setShowDownstreamModel] = useState(false);

  // New state variables for loading text
  const [loadingBlindspots, setLoadingBlindspots] = useState(false);
  const [loadingSyntheticImages, setLoadingSyntheticImages] = useState(false);
  const [loadingDownstreamModel, setLoadingDownstreamModel] = useState(false);

  useEffect(() => {
    if (fileUploaded) {
      setLoadingBlindspots(true); // Start showing loading text for blindspots
      const timer = setTimeout(() => {
        setShowBlindspots(true);
        setLoadingBlindspots(false); // Stop showing loading text
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [fileUploaded]);

  useEffect(() => {
    if (showBlindspots) {
      setLoadingSyntheticImages(true); // Start showing loading text for synthetic images
      const timer = setTimeout(() => {
        setShowSyntheticImages(true);
        setLoadingSyntheticImages(false); // Stop showing loading text
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [showBlindspots]);

  useEffect(() => {
    if (showSyntheticImages) {
      const timer = setTimeout(() => {
        setTestFileUploaded(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showSyntheticImages]);
  
  useEffect(() => {
    if (testFileUploadedOnChange) {
      setLoadingDownstreamModel(true); // Start showing loading text for downstream model
      const timer = setTimeout(() => {
        setShowDownstreamModel(true);
        setLoadingDownstreamModel(false); // Stop showing loading text
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [testFileUploadedOnChange]);

  return (
    <div className="App">
      <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">Advex AI System Demo</h1>
      <h1 className="mb-1 tracking-tight md:text-3xl text-gray-600">Unlocking Reliable Machine Vision for Industrial Automation</h1>
      <hr />

      {/* file upload */}
      <div id="initial-file-upload" className="card-no-shadow">
        <br />
        <br />
        {!fileUploaded && <FileUpload message={"Upload images to run Advex pipeline"} onFileUploaded={() => setFileUploaded(true)} />}
      </div>

      {/* Loading text for Blindspot generation */}
      {loadingBlindspots && 
          <h1 className="font-extrabold lg:text-3xl">
            <br></br>
            <br></br>
            Identifying regions with low or missing information...
          </h1>
      }

      {/* Blindspot generation */}
      {showBlindspots && (
        <div id="blindspot-container">
          <br/>
          <h1 className="font-extrabold lg:text-2xl">Generated Blindspots</h1>
          <br></br>
          <div className="card">
            <Blindspot blindspotData={blindspotData} />
          </div>
        </div>
      )}

      {/* Loading text for Image Generation */}
      {loadingSyntheticImages &&
        <h1 className="font-extrabold lg:text-3xl">
            <br></br>
            <br></br>
            <br></br>
            Filling in the gaps with synthetic data...
        </h1>
      }

      {/* Image Generation */}
      {showSyntheticImages && (
        <div id="synthetic-container">
          <br />
          <hr />
          <br></br>
          <h1 className="font-extrabold lg:text-2xl">Synthetic Images</h1>
          <br></br>
          <div className="card">
            <SyntheticImages syntheticData={syntheticData} />
          </div>
        </div>
      )}

      {/* file upload for downstream model */}
      {testFileUploaded && !showDownstreamModel && (
        <div id="test-images-upload" className="card-no-shadow">
          <br />
          <br />
          <FileUpload message={"Upload test images to train a segmentation model"} onFileUploaded={() => setTestFileUploadedOnChange(true)} />
        </div>
      )}

      {/* Loading text for Downstream Model */}
      {loadingDownstreamModel && 
        <h1 className="font-extrabold lg:text-3xl">
            <br></br>
            Training segmentation models with and without synthetic data...
          </h1>
      }

      {/* Model Finetuning */}
      {showDownstreamModel && (
        <div id="downstream-container">
          <br />
          <hr />
          <br />
          <h1 className="font-extrabold lg:text-2xl">Package segmentation Model</h1>
          <br />
          <p>
            <strong>Top row:</strong> Industry standard segmentation model.<strong> Accuracy: 55%</strong>
	<br />  
	<strong>Bottom row:</strong> Advex powered segmentation model. <strong> Accuracy: 88%</strong>
        
</p>
          <div className="downstream-card">
            <DownstreamModel testImages={testImages} />
          </div>
        </div>
      )}
    </div>
  );
}
