import "./Carousals.css";
import "react-multi-carousel/lib/styles.css";

import React, { useState } from 'react';
import Carousel from 'react-grid-carousel'

export const Blindspot = ({ blindspotData }) => {
  return (
    <Carousel cols={4} rows={1}>
      {blindspotData.map((item) => (
        <Carousel.Item key={item.id}>
          <div className="carousel-item-container">
            <br />
            {item.imagepath ? (
              <>
              <p className="font-bold">Blindspot {item.id}</p>
              <br />
                <img className="carousel-item-image" src={item.imagepath} alt={item.description} />
                <p>{item.description}</p>
              </>
            ) : (
              <>
                <p className="font-bold	">Blindspot {item.id}</p>
                <h3 className="no-image-blindspot"><span className="missing">Missing</span> {item.description}</h3>
              </>
            )}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export const SyntheticImages = ({ syntheticData }) => {
  return (
    <Carousel cols={3} rows={2} loop>
      {syntheticData.flatMap((item) => 
      // display title before mapping across images. title = item.description
        item.images.map((image, index) => (
          <Carousel.Item key={image.id}>
            <br />
            <div className="carousel-item-container">
              <img className="carousel-item-image-synthetic" src={image.imagepath} alt={image.description} />
              
              <div className="margin-between-images"></div>

              <img className="carousel-item-image-synthetic" src={image.maskpath} alt={image.description} />
              {index === 1 && <p><strong>Blindspot {item.blindspot_id}: </strong>{item.description}</p>}
            </div>
          </Carousel.Item>
        ))
      )}
    </Carousel>
  );
};

export const DownstreamModel = ({ testImages }) => {
  return (
    <Carousel cols={4} rows={2} loop>
      {testImages.map((item, index) => (
        <Carousel.Item key={item.id}>
        <br></br>
        <img className="carousel-item-image" src={item.imagepath} alt={item.description} />
       </Carousel.Item>
       
      ))}
    </Carousel>
  );
};

export const FileUpload = ({ message, onFileUploaded }) => {
  const [isUploaded, setIsUploaded] = useState(false); // Define the state here

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('Uploading:', file.name);
      // Simulate the upload process
      setIsUploaded(true); // Update the local state
      onFileUploaded(); // Notify the parent component about the upload
    }
  };

  if (isUploaded) {
    return null; // Component disappears after upload
  }

  return (
    <div className="flex items-center justify-center w-full">
      <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 dark:hover:bg-gray-800 dark:bg-gray-700 dark:border-gray-600 dark:hover:border-gray-500">
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">{message}</span></p>
              <p className="text-xs text-gray-500 dark:text-gray-400">PNG, JPG, or JPEG</p>
          </div>
          <input id="dropzone-file" type="file" className="hidden" onChange={handleFileChange} multiple/>
      </label>
    </div> 
  );
};