export const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
      slidesToSlide: 2,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  
export const blindspotData = [
  {
    id: 1,
    imagepath: '/assets/conveyor/blindspots/0012.png',
    description: 'Multiple objects on a conveyor',
  },
  // {
  //   id: 2,
  //   imagepath: '/assets/conveyor/blindspots/0072.png',
  //   description: 'Multipack object',
  // },
  {
    id: 2,
    imagepath: '/assets/conveyor/blindspots/0102.png',
    description: 'Objects in a bin',
  },
  {
    id: 3,
    imagepath: '',
    description: 'Roller conveyor belts',
  },
  {
    id: 4,
    imagepath: '',
    description: 'Multicolor bagged items',
  },
];

export const syntheticData = [
  {
    blindspot_id: 1,
    description: 'Multiple objects on a conveyor',
    images:
    [
      {
        id: 1,
        imagepath: '/assets/conveyor/synthetics/0071.png',
        maskpath: '/assets/conveyor/synthetics/0071 (1).png',
      },
      {
        id: 2,
        imagepath: '/assets/conveyor/synthetics/0099.png',
        maskpath: '/assets/conveyor/synthetics/0099 (1).png',
      },
      {
        id: 3,
        imagepath: '/assets/conveyor/synthetics/0169.png',
        maskpath: '/assets/conveyor/synthetics/0169 (1).png',
      },
      {
        id: 4,
        imagepath: '/assets/conveyor/synthetics/0210.png',
        maskpath: '/assets/conveyor/synthetics/0210 (1).png',
      },
      {
        id: 5,
        imagepath: '/assets/conveyor/synthetics/000015.png',
        maskpath: '/assets/conveyor/synthetics/000015 (1).png',
      },
      {
        id: 6,
        imagepath: '/assets/conveyor/synthetics/000534.png',
        maskpath: '/assets/conveyor/synthetics/000534 (1).png',
      },
    ],
  },
  // {
  //   blindspot_id: 2,
  //   description: 'Multipack object',
  //   images:
  //   [
  //     {
  //       id: 1,
  //       imagepath: '/assets/conveyor/synthetics/0009.png',
  //       maskpath: '/assets/conveyor/synthetics/0009 (1).png',
  //     },
  //     {
  //       id: 2,
  //       imagepath: '/assets/conveyor/synthetics/0006.png',
  //       maskpath: '/assets/conveyor/synthetics/0006 (1).png',
  //     },
  //     {
  //       id: 3,
  //       imagepath: '/assets/conveyor/synthetics/0033.png',
  //       maskpath: '/assets/conveyor/synthetics/0033 (1).png',
  //     },
  //     {
  //       id: 4,
  //       imagepath: '/assets/conveyor/synthetics/0072.png',
  //       maskpath: '/assets/conveyor/synthetics/0072 (1).png',
  //     },
  //   ],
  // },
  {
    blindspot_id: 2,
    description: 'Objects in a bin',
    images:
    [
      {
        id: 1,
        imagepath: '/assets/conveyor/synthetics/0106.png',
        maskpath: '/assets/conveyor/synthetics/0106 (1).png',
      },
      {
        id: 2,
        imagepath: '/assets/conveyor/synthetics/0112.png',
        maskpath: '/assets/conveyor/synthetics/0112 (1).png',
      },
      {
        id: 3,
        imagepath: '/assets/conveyor/synthetics/0114.png',
        maskpath: '/assets/conveyor/synthetics/0114 (1).png',
      },
      {
        id: 4,
        imagepath: '/assets/conveyor/synthetics/0116.png',
        maskpath: '/assets/conveyor/synthetics/0116 (1).png',
      },
      {
        id: 5,
        imagepath: '/assets/conveyor/synthetics/0123.png',
        maskpath: '/assets/conveyor/synthetics/0123 (1).png',
      },
      {
        id: 6,
        imagepath: '/assets/conveyor/synthetics/0128.png',
        maskpath: '/assets/conveyor/synthetics/0128 (1).png',
      },
      // {
      //   id: 7,
      //   imagepath: '/assets/conveyor/synthetics/0142.png',
      //   maskpath: '/assets/conveyor/synthetics/0142 (1).png',
      // },
    ],
  },
  {
    blindspot_id: 3,
    description: 'Roller conveyor belts',
    images:
    [
      {
        id: 1,
        imagepath: '/assets/conveyor/synthetics/0213.png',
        maskpath: '/assets/conveyor/synthetics/0213 (1).png',
      },
      {
        id: 2,
        imagepath: '/assets/conveyor/synthetics/0223.png',
        maskpath: '/assets/conveyor/synthetics/0223 (1).png',
      },
      {
        id: 3,
        imagepath: '/assets/conveyor/synthetics/0229.png',
        maskpath: '/assets/conveyor/synthetics/0229 (1).png',
      },
      {
        id: 4,
        imagepath: '/assets/conveyor/synthetics/0242.png',
        maskpath: '/assets/conveyor/synthetics/0242 (1).png',
      },
      {
        id: 5,
        imagepath: '/assets/conveyor/synthetics/0249.png',
        maskpath: '/assets/conveyor/synthetics/0249 (1).png',
      },
      {
        id: 6,
        imagepath: '/assets/conveyor/synthetics/0266.png',
        maskpath: '/assets/conveyor/synthetics/0266 (1).png',
      },
      // {
      //   id: 7,
      //   imagepath: '/assets/conveyor/synthetics/0273.png',
      //   maskpath: '/assets/conveyor/synthetics/0273 (1).png',
      // },
      // {
      //   id: 8,
      //   imagepath: '/assets/conveyor/synthetics/0288.png',
      //   maskpath: '/assets/conveyor/synthetics/0288 (1).png',
      // },
    ],
  },
  {
    blindspot_id: 4,
    description: 'Multicolor bagged items',
    images:
    [
      {
        id: 1,
        imagepath: '/assets/conveyor/synthetics/0014.png',
        maskpath: '/assets/conveyor/synthetics/0014 (1).png',
      },
      {
        id: 2,
        imagepath: '/assets/conveyor/synthetics/0017.png',
        maskpath: '/assets/conveyor/synthetics/0017 (1).png',
      },
      {
        id: 3,
        imagepath: '/assets/conveyor/synthetics/0019.png',
        maskpath: '/assets/conveyor/synthetics/0019 (1).png',
      },
      {
        id: 4,
        imagepath: '/assets/conveyor/synthetics/0032.png',
        maskpath: '/assets/conveyor/synthetics/0032 (1).png',
      },
      {
        id: 5,
        imagepath: '/assets/conveyor/synthetics/0035.png',
        maskpath: '/assets/conveyor/synthetics/0035 (1).png',
      },
      {
        id: 6,
        imagepath: '/assets/conveyor/synthetics/0044.png',
        maskpath: '/assets/conveyor/synthetics/0044 (1).png',
      },
      // {
      //   id: 7,
      //   imagepath: '/assets/conveyor/synthetics/0045.png',
      //   maskpath: '/assets/conveyor/synthetics/0045 (1).png',
      // },
      // {
      //   id: 8,
      //   imagepath: '/assets/conveyor/synthetics/0096.png',
      //   maskpath: '/assets/conveyor/synthetics/0096 (1).png',
      // },
      // {
      //   id: 9,
      //   imagepath: '/assets/conveyor/synthetics/0101.png',
      //   maskpath: '/assets/conveyor/synthetics/0101 (1).png',
      // },
      // {
      //   id: 10,
      //   imagepath: '/assets/conveyor/synthetics/0156.png',
      //   maskpath: '/assets/conveyor/synthetics/0156 (1).png',
      // },
    ],
  },
];

export const testImages = [
  {
    id: 1,
    imagepath: '/assets/conveyor/test/overlay_before/0100.png', // 1
  },
  {
    id: 2,
    imagepath: '/assets/conveyor/test/overlay_before/0134.png', // 2
  },
  {
    id: 3,
    imagepath: '/assets/conveyor/test/overlay_before/0195.png', // 3
  },
  {
    id: 4,
    imagepath: '/assets/conveyor/test/overlay_before/0301.png', // 4
  },
  {
    id: 5,
    imagepath: '/assets/conveyor/test/overlay_after/0100.png', // 5
  },
  {
    id: 6,
    imagepath: '/assets/conveyor/test/overlay_after/0134.png', // 6
  },
  {
    id: 7,
    imagepath: '/assets/conveyor/test/overlay_after/0195.png', // 7
  },
  {
    id: 8,
    imagepath: '/assets/conveyor/test/overlay_after/0301.png', // 8
  },
  {
    id: 9,
    imagepath: '/assets/conveyor/test/overlay_before/0110.png', // 9
  },
  {
    id: 10,
    imagepath: '/assets/conveyor/test/overlay_before/0164.png', // 10
  },
  {
    id: 11,
    imagepath: '/assets/conveyor/test/overlay_before/0285.png', // 11
  },
  {
    id: 12,
    imagepath: '/assets/conveyor/test/overlay_before/0312.png', // 12
  },
  {
    id: 13,
    imagepath: '/assets/conveyor/test/overlay_after/0110.png', // 13
  },
  {
    id: 14,
    imagepath: '/assets/conveyor/test/overlay_after/0164.png', // 14
  },
  {
    id: 15,
    imagepath: '/assets/conveyor/test/overlay_after/0285.png', // 15
  },
  {
    id: 16,
    imagepath: '/assets/conveyor/test/overlay_after/0312.png', // 16
  },

];
